import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { supabase } from "../utils/supabase"
import EpisodeModal from "../components/EpisodeModal"
import EditEpisodeModal from "../components/EditEpisode"
import FetchEpisodesModal from "../components/FetchEpisodes"
import DeleteEpisodesModal from "../components/DeleteEpisodes"
import axios from "axios"

function ManageEpisodes() {
    const { id } = useParams()
    const [season, setSeason] = useState(null)
    const [episodes, setEpisodes] = useState(null)
    const [showAddEpisode, setShowAddEpisode] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [apiObj, setApiObj] = useState([])
    const [showDeleteEpisode, setShowDeleteEpisode] = useState(false)

    useEffect(() => {
        getSeason()
        getEpisodes()

        supabase
        .channel("public:episodes")
        .on("postgres_changes", { event: "*", schema: "public", table: "episodes" }, (payload) => {
          getEpisodes()
        })
        .subscribe();
    }, [])

    const getSeason = async () => {
        const { data, error } = await supabase
        .from('seasons')
        .select('*, serie:series(id, title, tmdb_id, thumbnail_medium)')
        .eq('id', id)
        .single()

        if(data){
            setSeason(data)
        }
    }

    const getEpisodes = async () => {
        const { data, error } = await supabase
        .from('episodes')
        .select()
        .order('order', { ascending: true })
        .eq('season_id', id)

        if(data){
            setEpisodes(data)
        }
    }

    const handleFetchEpisodes = async () => {
      if(season && season.serie.tmdb_id){
        try {

          const { data: { episodes} } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/episodes/${season.serie.tmdb_id}/${season.order}`)

          if(episodes) {
            setApiObj(episodes)
          }

        } catch (error) {
          console.log(error)
        }
      } else {

      }
    }

  return (
    <div className='w-full h-screen overflow-y-scroll py-5'>
        <div className='flex justify-between w-full items-center mb-5'>
            <div>
                <h1>Episodes</h1>
                <p className='text-gray-400'>Manage Episodes</p>
            </div>
      </div>

      {apiObj?.length > 0 && <FetchEpisodesModal setShowAddSeason={setApiObj} apiObj={apiObj} season={season && season.id} />}

      <div className="mb-24 my-10 px-3 py-3 bg-[#2f3441] overflow-hidden">
        <div className='flex justify-between flex-col md:flex-row w-full gap-4'>
            <h3>{season && season.serie && `${season.serie.title} - ${season.title}`}</h3>
            <div className="flex gap-2 flex-col md:flex-row text-sm">
                <button className='px-3 py-1 font-medium bg-[#626ed4] rounded-sm' onClick={() => setShowAddEpisode(true)}>Add Episodes</button>
                <button className='px-3 py-1 font-medium bg-[#626ed4] rounded-sm' onClick={() => handleFetchEpisodes()}>Fetch All Episodes</button>
            </div>
        </div>
        <div className="overflow-x-scroll">
          <table className="w-full table-auto md:p-10 select-none overflow-visible">
            <thead>
              <tr>
                <th className="py-2 text-left pl-3">#</th>
                <th className="py-2 text-left pl-3">Thumbnail</th>
                <th className="py-2 text-left pl-3">Episode Name</th>
                <th className="py-2 text-left pl-3">Description</th>
                <th className="py-2 text-left pl-3">Status</th>
                <th className="py-2 text-left pl-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {episodes && episodes.length > 0 ? (
                  episodes.map((episode, index) => (
                      <tr key={episode.id} className="cursor-pointer mb-10 text-gray-300 text-sm border-b-[1px] border-gray-600 hover:bg-gray-600/30">
                          <td className="py-2 text-left pl-3">{episode.order}</td>
                          <td className="py-2 text-left pl-3">
                          <img src={season && season.serie.thumbnail_medium} alt="" className='w-20 h-20 object-cover' />
                          </td>
                          <td className="py-2 text-left pl-3">{episode.title}</td>
                          <td className="py-2 text-left pl-3">
                              <p className="line-clamp-3 w-24 md:w-64">{episode.overview}</p>
                          </td>
                          <td className="py-2 text-left pl-3">
                            <div className="flex flex-col items-start gap-1">
                              {episode.published ? <span className="text-xs bg-[#00acc1] px-2 py-1 rounded-md text-white">published</span> : <span className="text-xs bg-red-400 px-2 py-1 rounded-md text-white">unpublished</span>}
                            {episode.premium && <span className="text-xs bg-yellow-400 px-2 py-1 rounded-md text-white">premium</span>}
                            </div>
                          
                          </td>
                          <td className="py-2 text-left pl-3 flex gap-1 text-white">
                              <button className='px-3 py-1 bg-[#626ed4] rounded-sm text-sm' onClick={() => setSelectedIndex(episode)}>Edit</button>
                              <button className='px-3 py-1 bg-[#626ed4] rounded-sm text-sm' onClick={() => setShowDeleteEpisode(episode)}>Delete</button>
                          </td>
                          {selectedIndex && selectedIndex.id === episode.id && (
                            <EditEpisodeModal episode={episode} setShowAddSeason={setSelectedIndex} />
                          )}
                          {showDeleteEpisode && showDeleteEpisode.id === episode.id && (
                            <DeleteEpisodesModal setShowModal={setShowDeleteEpisode} episode={episode} />
                          )}
                      </tr>
                  )
                  )
              ) : (
                  <div className="flex w-full px-3 py-2 justify-between text-gray-400">
                    <p>No Episodes available</p>
                  </div>
              )}
            </tbody>
          </table>
        </div>
        {showAddEpisode && (
            <EpisodeModal setShowAddSeason={setShowAddEpisode} season={season && season.id} />
        )}
      </div>

    </div>
  )
}

export default ManageEpisodes