import { Formik, Form, ErrorMessage } from "formik"
import { supabase } from "../utils/supabase"

function VJModel({ setShowModal }) {

    const handleSubmit = async (values, resetForm) => {
        const { error } = await supabase
        .from('vjs')
        .insert(values)

        if (error) {
            alert(error.message)
        }
        resetForm({ name: "" })

        setShowModal(false)
    }
  return (
    <div className="h-screen w-screen bg-black bg-opacity-40 fixed top-0 left-0 bottom-0 right-0 z-50 flex justify-center items-center overflow-hidden">
      <div
        className="bg-[#2f3441] rounded-md w-11/12 md:w-8/12 lg:w-4/12 overflow-y-scroll overflow-x-hidden relative modalContain"
      >
        <h2 className="border-b-[1px] border-gray-500 px-4 py-4 font-medium text-lg text-gray-400">Add VJ</h2>
        <Formik
            initialValues={{
                name: "",
              }}
              enableReinitialize
              // validationSchema={loginSchema}
              onSubmit={(values, { resetForm }) => {handleSubmit(values, resetForm)}}
        >
        {({ values, handleChange, handleBlur }) => (
          <Form className="px-4 py-6 text-sm">
            <div className='flex flex-col gap-1 mb-5'>
                <label htmlFor="name">VJ Name</label>
                <input type="text" name="name" id="name" placeholder='Enter name' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("name")}
                onBlur={handleBlur("name")} value={values.name} />
            </div>

            <div className="flex justify-between">
                  <button type="submit" className='px-3 py-1 text-white font-medium bg-[#00acc1] text-sm rounded-md'>Add</button>
                  <button type="button" className='px-3 py-1 text-white font-medium border-[1px] border-[#00acc1] text-[#00acc1] text-sm rounded-md' onClick={() => setShowModal(false)}>Cancel</button>
              </div>
          </Form>)}
        </Formik>
        
      </div>
    </div>
  )
}

export default VJModel