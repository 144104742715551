import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { supabase } from "../utils/supabase"
import SeasonModal from "../components/SeasonModal"
import EditSeasonModal from "../components/EditSeason"
import { Link } from "react-router-dom"
import FetchSeasonsModal from "../components/FetchSeasons"
import DeleteSeasonModal from "../components/DeleteSeason"
import axios from "axios"

function ManageSeasons() {
    const { id } = useParams()
    const [seasons, setSeasons] = useState(null)
    const [serie, setSerie] = useState(null)
    const [showAddSeason, setShowAddSeason] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [apiObj, setApiObj] = useState(null)
    const [showDeleteSeason, setShowDeleteSeason] = useState(false)

    useEffect(() => {
        getSerie()
        getSeasons()

        supabase
        .channel("public:seasons")
        .on("postgres_changes", { event: "*", schema: "public", table: "seasons" }, (payload) => {
          getSeasons()
        })
        .subscribe();
    }, [])

    const getSerie = async () => {
        const { data, error } = await supabase
        .from('series')
        .select()
        .eq('id', id)
        .single()

        if(data){
            setSerie(data)
        }
    }

    const getSeasons = async () => {
        const { data, error } = await supabase
        .from('seasons')
        .select()
        .order('order', { ascending: true })
        .eq('serie_id', id)

        if(data){
            setSeasons(data)
        }
    }
  
    const handleFetchSeasons = async () => {
      if(serie.tmdb_id){
        const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/seasons/${serie.tmdb_id}/`)
        setApiObj(data)
      } else {

      }
    }

  return (
    <div className='w-full h-screen overflow-y-scroll py-5'>
        <div className='flex justify-between w-full items-center mb-5'>
            <div>
                <h1>Seasons</h1>
                <p className='text-gray-400'>Manage Seasons</p>
            </div>
      </div>

      {apiObj && <FetchSeasonsModal setShowAddSeason={setApiObj} apiObj={apiObj} serie={serie.id} />}

      <div className="mb-24 my-10 px-3 py-3 bg-[#2f3441]">
        <div className='flex justify-between flex-col md:flex-row w-full gap-4'>
            <h3>{serie && serie.title}</h3>
            <div className="flex gap-2 flex-col md:flex-row">
                <button className='px-3 py-1 font-medium bg-[#626ed4] rounded-sm' onClick={() => setShowAddSeason(true)}>Add Season</button>
                <button className='px-3 py-1 font-medium bg-[#626ed4] rounded-sm' onClick={() => handleFetchSeasons()}>Fetch All Season</button>
            </div>
        </div>
        <table className="w-full table-auto md:p-10 select-none overflow-visible">
          <thead>
            <tr>
              <th className="py-2 text-left pl-3">#</th>
              <th className="py-2 text-left pl-3">Season Name</th>
              <th className="py-2 text-left pl-3">Order</th>
              <th className="py-2 text-left pl-3">Episodes</th>
              <th className="py-2 text-left pl-3">Status</th>
              <th className="py-2 text-left pl-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {seasons && seasons.length > 0 ? (
                seasons.map((season, index) => (
                    <tr key={season.id} className="cursor-pointer mb-10">
                        <td className="py-2 text-left pl-3">{index + 1}</td>
                        <td className="py-2 text-left pl-3">{season.title}</td>
                        <td className="py-2 text-left pl-3">{season.order}</td>
                        <td className="py-2 text-left pl-3">{season.episode_count}</td>
                        <td className="py-2 text-left pl-3">
                          {season.published ? <span className="text-xs bg-[#00acc1] px-2 py-1 rounded-md text-white">published</span> : <span className="text-xs bg-red-400 px-2 py-1 rounded-md text-white">unpublished</span>}
                        </td>
                        <td className="py-2 text-left pl-3 flex gap-1">
                            <Link to={`/manage_episodes/${season.id}`} className='px-3 py-1 bg-[#626ed4] rounded-sm text-sm'>Manage Episodes</Link>
                            <button className='px-3 py-1 bg-[#626ed4] rounded-sm text-sm' onClick={() => setSelectedIndex(season)}>Edit</button>
                            <button className='px-3 py-1 bg-[#626ed4] rounded-sm text-sm' onClick={() => setShowDeleteSeason(season)}>Delete</button>
                        </td>
                        {selectedIndex && selectedIndex.id === season.id && (
                          <EditSeasonModal season={season} setShowAddSeason={setSelectedIndex} />
                        )}
                        {showDeleteSeason && showDeleteSeason.id === season.id && (
                          <DeleteSeasonModal setShowModal={setShowDeleteSeason} season={season} />
                        )}
                    </tr>
                )
                )
            ) : (
                <div className="flex w-full px-3 py-2 justify-between text-gray-400">
                  <p>No Seasons available</p>
                </div>
            )}
          </tbody>
        </table>
        {showAddSeason && (
          <SeasonModal setShowAddSeason={setShowAddSeason} serie={serie.id} />
        )}
      </div>

    </div>
  )
}

export default ManageSeasons