import Logo from "../assets/images/Logo3.png";

const DownloadPage = () => {
  return (
    <div className='w-full h-screen overflow-y-scroll py-5 flex flex-col justify-center items-center'>
        <div className='flex flex-col items-center justify-between w-full items-center'>
            <img src={Logo} alt="Logo" className="h-[50px] w-[50px]" />
            <h1>Kampala Relaxation Apk</h1>
        </div>
      <p className="mb-5">Click the link below to download the APK file:</p>
      <a href="/kla.apk" download="KampalaRelaxation.apk" className='px-3 py-2 text-white font-medium bg-[#00acc1]'>
        Download APK
      </a>
    </div>
  );
};

export default DownloadPage;
