import { Formik, Form, FieldArray, useFormik } from "formik"
import { supabase } from "../utils/supabase"

function FetchEpisodesModal({ setShowAddSeason, apiObj, season}) {

    const handleSubmit = async (values, resetForm) => {
        const { error } = await supabase
        .from('episodes')
        .insert(values)

        if (error) {
            alert(error.message)
        }

        setShowAddSeason(null)
    }

    const formik = useFormik({
        initialValues: {
        episodes: apiObj?.map((episode) => ({
            title: episode.name,
            season_id: season,
            order: episode.episode_number,
            thumbnail_medium: episode.still_path,
            video_link: "",
            published: true,
            premium: false,
            air_date: episode.air_date,
            name: episode.name,
            overview: episode.overview,
            runtime: episode.runtime,
            season_number: episode.season_number,
          })),
        }
      });

  return (
    <div className="h-screen w-screen bg-black bg-opacity-40 fixed top-0 left-0 bottom-0 right-0 z-50 flex justify-center items-center overflow-hidden">
      <div
        className="bg-[#2f3441] rounded-md w-screen h-screen overflow-y-scroll overflow-x-hidden relative modalContain px-4 py-6"
      >
        <h2>Fetch Episodes</h2>
        <hr />
        <Formik
            initialValues={formik.initialValues}
              enableReinitialize
              // validationSchema={loginSchema}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(formik.values.episodes, resetForm)
            }}
        >
        {({ values, handleChange, handleBlur }) => (
          <Form>
                
            
            <table className="w-full table-auto md:p-10 select-none my-5">
                <thead>
                    <tr>
                        <th className="py-2 text-left pl-3">#</th>
                        <th className="py-2 text-left pl-3">Name</th>
                        <th className="py-2 text-left pl-3">Thumbnail</th>
                        <th className="py-2 text-left pl-3">Description</th>
                        <th className="py-2 text-left pl-3">Video link</th>
                        <th className="py-2 text-left pl-3">Publish</th>
                        <th className="py-2 text-left pl-3">Premium</th>
                    </tr>
                </thead>
                <tbody>
                    <FieldArray name="episodes"
                    render={(arrayHelpers) => (
                        <>
                            {formik.values.episodes.map((episode, index) => (
                                <tr key={index} className="text-sm text-gray-300">
                                    <td className="py-2 text-left pl-3">{episode.order}</td>
                                    <td className="py-2 text-left pl-3">
                                        <input type="text" name={`episodes.${index}.title`} id="title" placeholder='Enter title' className='bg-transparent border border-gray-400 px-3 py-2 rounded w-full' onChange={formik.handleChange}
                                        onBlur={formik.onBlur} value={episode.title} />
                                    </td>
                                    
                                    <td className="py-2 text-left pl-3">
                                        <img src={`https://image.tmdb.org/t/p/w200${episode.thumbnail_medium}`} alt="" className='w-20 h-20 object-cover' />
                                    </td>
                                    <td className="py-2 text-left pl-3">
                                        <textarea type="text" name={`episodes.${index}.overview`} id="overview" placeholder='Enter overview' className='bg-transparent border border-gray-400 px-3 py-2 rounded w-full' onChange={formik.handleChange}
                                            onBlur={formik.onBlur} value={episode.overview} />
                                    </td>
                                    <td className="py-2 text-left pl-3">
                                        <input type="text" name={`episodes.${index}.video_link`} id="video_link" placeholder='Enter link' className='bg-transparent border border-gray-400 px-3 py-2 rounded w-full' onChange={formik.handleChange}
                                            onBlur={formik.onBlur} value={episode.video_link} />
                                    </td>
                                    <td className="py-2 text-left pl-3">
                                        <label className="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" name={`episodes.${index}.published`} id="published" onChange={formik.handleChange}
                                    onBlur={formik.onBlur} value={values.published} checked={episode.published}  className="sr-only peer" />
                                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                        </label>
                                    </td>
                                    <td className="py-2 text-left pl-3">
                                        <label className="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" name={`episodes.${index}.premium`} id="premium" onChange={formik.handleChange}
                                    onBlur={formik.onBlur} value={values.premium} checked={episode.premium}  className="sr-only peer" />
                                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                        </label>
                                    </td>
                                </tr>

        ))}
                        </>
                    )}
                    />
                </tbody>
            </table>
            
            

            <div className="flex justify-between">
                  <button type="submit" className='px-3 py-1 text-white font-medium bg-[#00acc1]'>Create</button>
                  <button type="button" className='px-3 py-1 text-white font-medium border border-[#00acc1] text-[#00acc1]' onClick={() => setShowAddSeason(null)}>Cancel</button>
              </div>
          </Form>)}
        </Formik>
        
      </div>
    </div>
  )
}

export default FetchEpisodesModal