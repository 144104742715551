import { Formik, Form, ErrorMessage } from "formik"
import { useState, useEffect } from "react"
import { supabase } from "../utils/supabase"
import { MdOutlineClose } from "react-icons/md";
import { MdAdd } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function EditMovie() {
    const [apiObj, setApiObj] = useState(null)
    const [vjs, setVjs] = useState(null)
    const { id } = useParams()
    const navigate = useNavigate()
    const [genres, setGenres] = useState(null)
  
    useEffect(() => {
      getVJs()
      getMovie()
      getGenres()
    }, [])

    const getMovie = async () => {
        const { data, error } = await supabase
        .from('movies')
        .select()
        .eq('id', id)
    
        if(data){
            setApiObj(data[0])
        }
    }

    const getGenres = async () => {
      const { data, error } = await supabase
      .from('genres')
      .select('id, name')
      .order('name', { ascending: true })
  
      if(data){
        setGenres(data)
      }
    }
  
    const getVJs = async () => {
      const { data, error } = await supabase
      .from('vjs')
      .select()
      .order('name', { ascending: true })
  
      if(data){
        setVjs(data)
      }
    }
  
    const handleSubmit = async (values, resetForm) => {
      const { error } = await supabase
      .from('movies')
      .update(values)
      .eq('id', id)
  
      if (error) {
        alert(error.message)
      }
  
      setApiObj(null)
      resetForm()

      navigate('/all-movies')
    }
  
    return (
      <div className='w-full h-screen overflow-y-scroll py-5'>
        <div className='flex justify-between w-full items-center'>
          <div>
            <h1>Edit Movie</h1>
            <p className='text-gray-400'>Edit Movie</p>
          </div>
        </div>
  
        <Formik
          initialValues={{
            title: apiObj?.title ? apiObj.title : "",
            vj: apiObj?.vj ? apiObj.vj : "",
            overview: apiObj?.overview ? apiObj.overview : "",
            genre: apiObj?.genre ? apiObj.genre : [],
            date: apiObj?.date ? apiObj.date : "",
            duration: apiObj?.duration ? apiObj.duration : "",
            trailer: apiObj?.trailer ? apiObj.trailer : "",
            video_link: apiObj?.video_link ? apiObj.video_link : "",
            thumbnail_medium: apiObj?.thumbnail_medium ? apiObj.thumbnail_medium : "",
            cover: apiObj?.cover ? apiObj.cover : "",
            popular: apiObj?.popular ? apiObj.popular : false,
            latest: apiObj?.latest ? apiObj.latest : false,
            recommend: apiObj?.recommend ? apiObj.recommend : false,
            published: apiObj?.published ? apiObj.published : false,
            premium: apiObj?.premium ? apiObj.premium : false,
          }}
          enableReinitialize
          // validationSchema={loginSchema}
          onSubmit={(values, { resetForm }) => {handleSubmit(values, resetForm)}}
        >
          {({ values, handleChange, handleBlur, setFieldValue }) => (
            <Form>
            <div className='my-4 mb-10 pb-10 grid md:grid-cols-2 gap-4'>
              <div className='bg-[#2f3441] px-3 py-2'>
                <div className='border-b border-gray-500 py-2'>
                  <h3>Movie Info</h3>
                </div>
                <div className='py-3'>
                  <div className='flex flex-col gap-1 mb-3'>
                    <label htmlFor="title">Title</label>
                    <input type="text" name="title" id="title" placeholder='Enter title' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("title")}
                    onBlur={handleBlur("title")} value={values.title} />
                  </div>
                  <div className='flex flex-col gap-1 mb-3'>
                    <label htmlFor="vj">VJ</label>
                    <select name="vj" id="vj" className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("vj")} onBlur={handleBlur("vj")} value={values.vj}>
                      <option value="">Choose VJ</option>
                      {vjs && vjs.map((vj, index) => (
                        <option value={vj.id} key={index}>{vj.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className='flex flex-col gap-1 mb-3'>
                    <label htmlFor="overview">Overview</label>
                    <textarea type="text" name="overview" id="overview" placeholder='Enter description' className='bg-transparent border border-gray-400 px-3 py-2 rounded' rows={8} onChange={handleChange("overview")}
                    onBlur={handleBlur("overview")} value={values.overview} />
                  </div>
  
  
                  <div className='flex flex-col gap-1 mb-3'>
                  <label htmlFor="genres">Genres</label>
                  <div className='bg-transparent border border-gray-400 px-3 py-2 rounded'>
                    <div className="flex items-end gap-1 flex-wrap">
                    {values.genre && values.genre.length > 0 && values.genre.map((genre, index) => {
                      if(index === values.genre.length - 1){
                        return <div key={index} className='mr-1 border-[1px] p-1 rounded-md border-gray-400 text-xs text-gray-400 flex items-center gap-1'>
                          {typeof genre === 'string' ? JSON.parse(genre).name : genre.name}
                          <MdOutlineClose className="cursor-pointer" onClick={() => {
                            const updatedGenres = values.genre.filter((genre, indexToRemove) => index !== indexToRemove);
                            setFieldValue("genre", updatedGenres);
                          }} />
                        </div>
                      }else{
                        return <div className='text-xs text-gray-400 flex items-end'>
                          <div key={index} className='border-[1px] p-1 rounded-md border-gray-400 text-xs text-gray-400 flex items-center gap-1'>
                            {typeof genre === 'string' ? JSON.parse(genre).name : genre.name}
                            <MdOutlineClose className="cursor-pointer" onClick={() => {
                            const updatedGenres = values.genre.filter((genre, indexToRemove) => index !== indexToRemove);
                            setFieldValue("genre", updatedGenres);
                          }} />
                          </div>,
                        </div>
                      }
                    })}
                    </div>

                    <select className='bg-transparent border border-gray-400 px-3 py-2 rounded text-xs text-gray-400 mt-1' onChange={(event) => {
                      const genre = genres.filter(genre => genre.id === event.target.value)
                      values.genre.push(genre[0])
                      setFieldValue("genre", values.genre)
                    }}>
                    <option value="">Add genre</option>
                    {genres && genres.map((genre, index) => (
                      <option value={genre.id} key={index}>{genre.name}</option>
                    ))}
                  </select>
                    
                  </div>
                </div>
  
                  <div className='flex flex-col gap-1 mb-3'>
                    <label htmlFor="title">Release Date</label>
                    <input type="date" name="date" id="date" placeholder='Enter date' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("date")}
                    onBlur={handleBlur("date")} value={values.date} />
                  </div>
                  <div className='flex flex-col gap-1 mb-3'>
                    <label htmlFor="duration">Duration</label>
                    <input type="text" name="duration" id="duration" placeholder='Enter duration' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("duration")}
                    onBlur={handleBlur("duration")} value={values.duration} />
                  </div>
                  <div className='flex flex-col gap-1 mb-3'>
                    <label htmlFor="trailer">Trailer URL</label>
                    <input type="text" name="trailer" id="trailer" placeholder='Enter trailer' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("trailer")}
                    onBlur={handleBlur("trailer")} value={values.trailer} />
                  </div>
                  <div className='flex flex-col gap-1 mb-3'>
                    <label htmlFor="video_link">Video Link</label>
                    <input type="text" name="video_link" id="video_link" placeholder='Enter video url' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("video_link")}
                    onBlur={handleBlur("video_link")} value={values.video_link} />
                  </div>
                </div>
              </div>
  
              {/* Additional Info */}
              <div className='bg-[#2f3441]'>
                <div className='bg-[#2f3441] px-3 py-2'>
                  <div className='border-b border-gray-500 py-2'>
                    <h3>Additional Info</h3>
                  </div>
                  <div className='py-3'>
                    <div className='flex flex-col gap-1 mb-3'>
                      <label htmlFor="thumbnail_medium">Thumbnail</label>
                      <img src={values.thumbnail_medium ? values.thumbnail_medium : "https://via.placeholder.com/150"} alt="" className='w-[150px] h-[180px] mb-2' />
                      <input type="text" name="thumbnail_medium" id="thumbnail_medium" placeholder='Enter thumbnail' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("thumbnail_medium")} onBlur={handleBlur("thumbnail_medium")} value={values.thumbnail_medium} />
                    </div>
                    <div className='flex flex-col gap-1 mb-3'>
                      <label htmlFor="cover">Cover</label>
                      <img src={values.cover ? values.cover : "https://via.placeholder.com/150"}  alt="" className='w-[350px] h-[150px] mb-2' />
                      <input type="text" name="cover" id="cover" placeholder='Enter cover' className='bg-transparent border border-gray-400 px-3 py-2 rounded' onChange={handleChange("cover")}
                  onBlur={handleBlur("cover")} value={values.cover} />
                    </div>


                    {/* Popular, Lastest, Recommended */}
                  <div className='flex gap-4 mb-5 mt-5'>
                    <label htmlFor="popular">Popular</label>

                    <label class="relative inline-flex items-center cursor-pointer">
                      <input type="checkbox" name="popular" id="popular" onChange={handleChange("popular")}
                  onBlur={handleBlur("popular")} value={values.popular} checked={values.popular}  class="sr-only peer" />
                      <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>

                  <div className='flex gap-4 mb-5 mt-5'>
                    <label htmlFor="latest">Latest</label>
                    <label class="relative inline-flex items-center cursor-pointer">
                      <input type="checkbox" name="latest" id="latest" onChange={handleChange("latest")}
                onBlur={handleBlur("latest")} value={values.latest} checked={values.latest}  class="sr-only peer" />
                      <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>

                  <div className='flex gap-4 mb-5 mt-5'>
                    <label htmlFor="recommend">Recommend</label>

                    <label class="relative inline-flex items-center cursor-pointer">
                      <input type="checkbox" name="recommend" id="recommend" onChange={handleChange("recommend")}
                onBlur={handleBlur("recommend")} value={values.recommend} checked={values.recommend}  class="sr-only peer" />
                      <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                  </div>


                <div className='flex gap-1 md: gap-3 mb-5'>
                  <label htmlFor="publish">Publish</label>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" name="published" id="published" onChange={handleChange("published")}
                  onBlur={handleBlur("published")} value={values.published} checked={values.published}  className="sr-only peer" />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
              </div>

                <div className='flex gap-1 md: gap-3 mb-5'>
                  <label htmlFor="premium">Premium</label>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" name="premium" id="premium" onChange={handleChange("premium")}
                  onBlur={handleBlur("premium")} value={values.premium} checked={values.premium}  className="sr-only peer" />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
              </div>


                  
                </div>
  
                <div className="w-full flex justify-end">
                    <button type="submit" className='px-3 py-2 text-white font-medium bg-[#00acc1] flex items-center gap-1'>
                      <MdAdd size={25} />
                      Update
                    </button>
                </div>
  
                </div>
  
                
            
            
              </div>
            </div>
          </Form>
          )}
          
        </Formik>
  
      </div>
    )
}

export default EditMovie