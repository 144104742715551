import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import PrivateRoutes from "./routes/PrivateRoutes";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import AddMovies from "./pages/AddMovies";
import Movies from "./pages/Movies";
import AddSeries from "./pages/AddSeries";
import Series from "./pages/Series";
import Bulk from "./pages/Bulk";
import Genres from "./pages/Genres";
import Users from "./pages/Users";
import VJs from "./pages/VJs";
import Search from "./pages/Search";
import ManageSeasons from "./pages/ManageSeasons";
import ManageEpisodes from "./pages/ManageEpisodes";
import EditMovie from "./pages/EditMovie";
import EditSerie from "./pages/EditSerie";
import Subscriptions from "./pages/Subscriptions";
import SlidePreview from "./pages/SlidePreview";
import DownloadPage from "./pages/DownloadApK";
import Notifications from "./pages/Notifications";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DeleteAccount from "./pages/DeleteAccount";

function App() {
  return (
    <BrowserRouter>
    <Toaster
          toastOptions={{
            className: "",
            success: {
              iconTheme: {
                primary: "white",
                secondary: "green",
              },
              style: {
                color: "white",
                padding: "8px 20px",
                background: "green",
              },
            },
            error: {
              style: {
                color: "white",
                padding: "8px 20px",
                background: "red",
              },
            },
          }}
      />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="/android-app" element={<DownloadPage />} />
        <Route
          path="/"
          element={
            <PrivateRoutes>
              <Dashboard />
            </PrivateRoutes>
          }
        />
        <Route
          path="/add-movies"
          element={
            <PrivateRoutes>
              <AddMovies />
            </PrivateRoutes>
          }
        />
        <Route
          path="/all-movies"
          element={
            <PrivateRoutes>
              <Movies />
            </PrivateRoutes>
          }
        />
        <Route
          path="/all-series"
          element={
            <PrivateRoutes>
              <Series />
            </PrivateRoutes>
          }
        />
        <Route
          path="/add-series"
          element={
            <PrivateRoutes>
              <AddSeries />
            </PrivateRoutes>
          }
        />
        <Route
          path="/bulk"
          element={
            <PrivateRoutes>
              <Bulk />
            </PrivateRoutes>
          }
        />
        <Route
          path="/genres"
          element={
            <PrivateRoutes>
              <Genres />
            </PrivateRoutes>
          }
        />
        <Route
          path="/search"
          element={
            <PrivateRoutes>
              <Search />
            </PrivateRoutes>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoutes>
              <Users />
            </PrivateRoutes>
          }
        />
        <Route
          path="/subscriptions"
          element={
            <PrivateRoutes>
              <Subscriptions />
            </PrivateRoutes>
          }
        />
        <Route
          path="/notifications"
          element={
            <PrivateRoutes>
              <Notifications />
            </PrivateRoutes>
          }
        />
        <Route
          path="/slide"
          element={
            <PrivateRoutes>
              <SlidePreview />
            </PrivateRoutes>
          }
        />
        <Route
          path="/vjs"
          element={
            <PrivateRoutes>
              <VJs />
            </PrivateRoutes>
          }
        />
        <Route
          path="/manage_seasons/:id"
          element={
            <PrivateRoutes>
              <ManageSeasons />
            </PrivateRoutes>
          }
        />
        <Route
          path="/manage_episodes/:id"
          element={
            <PrivateRoutes>
              <ManageEpisodes />
            </PrivateRoutes>
          }
        />
        <Route
          path="/edit/:id"
          element={
            <PrivateRoutes>
              <EditMovie />
            </PrivateRoutes>
          }
        />
        <Route
          path="/edit-series/:id"
          element={
            <PrivateRoutes>
              <EditSerie />
            </PrivateRoutes>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
