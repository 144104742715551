import { useState, useEffect } from "react"
import { supabase } from "../utils/supabase"
import GenreModal from "../components/GenreModal"
import EditGenreModal from "../components/EditGenreModal"
import DeleteGenreModal from "../components/DeleteGenre"

function Genres() {

  const [showModal, setShowModal] = useState(false)
  const [genres, setGenres] = useState(null)
  const [selectedGenre, setSelectedGenre] = useState(null)
  const [deleteGenre, setDeleteGenre] = useState(null)

  useEffect(() => {
    getGenres()

    supabase
        .channel("public:genres")
        .on("postgres_changes", { event: "*", schema: "public", table: "genres" }, (payload) => {
          getGenres()
        })
        .subscribe();
  }, [])

  const getGenres = async () => {
    const { data, error } = await supabase
    .from('genres')
    .select()
    .order('name', { ascending: true })

    if(data){
      setGenres(data)
    }
  }

  return (
    <div className='w-full h-screen overflow-y-scroll py-5'>
      <div className='flex justify-between w-full items-center'>
        <div>
            <h1>Genres</h1>
            <p className='text-gray-400'>Manage Genres</p>
          </div>

          <button className='px-3 py-2 text-white font-medium bg-[#00acc1] text-sm rounded-md' onClick={() => setShowModal(true)}>Add Genre</button>
      </div>
      {showModal && <GenreModal setShowModal={setShowModal} />}

      <div className="mb-24 my-10 px-3 py-3 bg-[#2f3441]">
        <table className="w-full table-auto md:p-10 select-none overflow-visible">
          <thead>
            <tr className="border-b-[1px] border-gray-600">
              <th className="py-2 text-left pl-3">#</th>
              <th className="py-2 text-left pl-3">Name</th>
              <th className="py-2 text-left pl-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {genres && genres.map((genre, index) => (
              <tr key={genre.id} className="cursor-pointer mb-10 text-gray-400 border-b-[1px] border-gray-700 hover:bg-gray-600/30">
                <td className="py-2 text-left pl-3">{index + 1}</td>
                <td className="py-2 text-left pl-3">{genre.name}</td>
                <td className="py-2 text-left pl-3 flex gap-1">
                  <button className='px-3 py-1 bg-[#626ed4] text-white text-sm rounded-md' onClick={() => setSelectedGenre(genre)}>Edit</button>
                  <button className='px-3 py-1 bg-[#626ed4] text-white text-sm rounded-md' onClick={() => setDeleteGenre(genre)}>Delete</button>
                </td>
                {selectedGenre && selectedGenre.id === genre.id && <EditGenreModal setShowModal={setSelectedGenre} genre={genre} />}
                {deleteGenre && deleteGenre.id === genre.id && <DeleteGenreModal setShowModal={setDeleteGenre} genre={genre} />}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  )
}

export default Genres