import { supabase } from "../utils/supabase"
import { useState, useEffect } from "react"
import VJModel from "../components/VJModel"
import EditVJModal from "../components/EditVJModal"
import DeleteVJModal from "../components/DeleteVJ"

function VJs() {

  const [vjs, setVjs] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [selectedVJ, setSelectedVJ] = useState(null)
  const [deleteVJ, setDeleteVJ] = useState(null)

  useEffect(() => {
    getVJs()

    supabase
        .channel("public:vjs")
        .on("postgres_changes", { event: "*", schema: "public", table: "vjs" }, (payload) => {
          getVJs()
        })
        .subscribe();
  }, [])

  const getVJs = async () => {
    const { data, error } = await supabase
    .from('vjs')
    .select()
    .order('name', { ascending: true })

    if(data){
      setVjs(data)
    }
  }

  return (
    <div className='w-full h-screen overflow-y-scroll py-5'>
      <div className='flex justify-between w-full items-center'>
        <div>
          <h1>VJS</h1>
          <p className='text-gray-400'>All VJs</p>
        </div>

        <button className='px-3 py-2 text-white font-medium bg-[#00acc1] text-sm rounded-md' onClick={() => setShowModal(true)}>Add VJ</button>
      </div>
      {showModal && <VJModel setShowModal={setShowModal} />}

      <div className="mb-24 my-10 px-3 py-3 bg-[#2f3441]">
        <table className="w-full table-auto md:p-10 select-none overflow-visible">
          <thead>
            <tr className="border-b-[1px] border-gray-600">
              <th className="py-2 text-left pl-3">#</th>
              <th className="py-2 text-left pl-3">Name</th>
              <th className="py-2 text-left pl-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {vjs && vjs.map((vj, index) => (
              <tr key={vj.id} className="cursor-pointer mb-10 text-gray-400 border-b-[1px] border-gray-700 hover:bg-gray-600/30">
                <td className="py-2 text-left pl-3">{index + 1}</td>
                <td className="py-2 text-left pl-3">{vj.name}</td>
                <td className="py-2 text-left pl-3 flex gap-1">
                  <button className='px-3 py-1 bg-[#626ed4] text-white text-sm rounded-md' onClick={() => setSelectedVJ(vj)}>Edit</button>
                  <button className='px-3 py-1 bg-[#626ed4] text-white text-sm rounded-md' onClick={() => setDeleteVJ(vj)}>Delete</button>
                </td>
                {selectedVJ && selectedVJ.id === vj.id && <EditVJModal setShowModal={setSelectedVJ} vj={vj} />}
                {deleteVJ && deleteVJ.id === vj.id && <DeleteVJModal setShowModal={setDeleteVJ} vj={vj} />}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default VJs