import Card from '../components/Card'
import { useEffect, useState } from "react"
import { supabase } from "../utils/supabase"
import { PiFilmSlateBold } from "react-icons/pi";
import { RiMovie2Fill } from "react-icons/ri";
import { MdUnpublished } from "react-icons/md";
import { adminAuthClient } from "../utils/supabase"
import { Link } from 'react-router-dom';

function Dashboard() {

  const [movies, setMovies] = useState(null)
  const [trendingMovies, setTrendingMovies] = useState(null)
  const [unpublisedMovies, setUnpublishedMovies] = useState(null)
  const [series, setSeries] = useState(null)
  const [unpublisedSeries, setUnpublishedSeries] = useState(null)
  const [users, setUsers] = useState(null)

  useEffect(() => {
    getMovies()
    getUplishedMovies()
    getUplishedSeries()
    getSeries()
    getUsers()
    getTrendingMovies()

    supabase
        .channel("public:movies")
        .on("postgres_changes", { event: "*", schema: "public", table: "movies" }, (payload) => {
          getMovies()
        })
        .subscribe();
  }, [])

  const getUsers = async () => {
    const { data: { users }, error } = await adminAuthClient.listUsers()
    if(users){
      setUsers(users)
    }
  }

  const getMovies = async () => {
    const { data, error } = await supabase
    .from('movies')
    .select()
    .order('created_at', { ascending: false })

    if(data){
      setMovies(data)
    }
  }

  const getTrendingMovies = async () => {
    const { data, error } = await supabase
    .from('movies')
    .select()
    .order('views', { ascending: false })

    if(data){
      setTrendingMovies(data)
    }
  }

  const getUplishedMovies = async () => {
    const { data, error } = await supabase
    .from('movies')
    .select()
    .eq('published', false)
    .order('created_at', { ascending: false })

    if(data){
      setUnpublishedMovies(data)
    }
  }

  const getUplishedSeries = async () => {
    const { data, error } = await supabase
    .from('series')
    .select()
    .eq('published', false)
    .order('created_at', { ascending: false })

    if(data){
      setUnpublishedSeries(data)
    }
  }

  const getSeries= async () => {
    const { data, error } = await supabase
    .from('series')
    .select()
    .order('created_at', { ascending: false })

    if(data){
      setSeries(data)
    }
  }

  return (
    <div className='w-full h-screen overflow-y-scroll py-5'>
      <div className='flex justify-between w-full items-center'>
        <div>
          <h1>Dashboard</h1>
          <p className='text-gray-400'>Welcome to the Dashboard</p>
        </div>
      </div>

      <div className='w-full items-center grid md:grid-cols-4 gap-4 my-5'>
        <Card title='TOTAL MOVIES' value={movies && movies.length} icon={<PiFilmSlateBold size={25} />} />
        <Card title='UNPUBLISH MOVIES' value={unpublisedMovies && unpublisedMovies.length} icon={<MdUnpublished size={25} />} />
        <Card title='TOTAL SERIES' value={series && series.length} icon={<RiMovie2Fill size={25} />} />
        <Card title='UNPUBLISH SERIES' value={unpublisedSeries && unpublisedSeries.length} icon={<MdUnpublished size={25} />} />
      </div>


      <div className='my-5 flex flex-col md:flex-row gap-4 w-full'>

        {/* Popular movies */}
        <div className='bg-[#2f3441] p-3 md:p-5 text-gray-400 w-full'>
          <h2 className='text-white'>Most Popular Movies</h2>
          <table className="w-full table-auto md:p-10 select-none overflow-visible">
              <thead>
                <tr className='border-b-[1px] border-gray-600'>
                  <th className="py-2 text-left pl-3">#</th>
                  <th className="py-2 text-left pl-3">Title</th>
                  <th className="py-2 text-left pl-3">View</th>
                  <th className="py-2 text-left pl-3">Edit</th>
                </tr>
              </thead>
              <tbody>
                {trendingMovies && trendingMovies.slice(0, 5).map((movie, index) => (
                  <tr key={movie.id} className="cursor-pointer mb-10 border-b-[1px] border-gray-700 hover:bg-gray-600/30">
                    <td className="py-2 text-left pl-3">{index + 1}</td>
                    <td className="py-2 text-left pl-3">{movie.title}</td>
                    <td className="py-2 text-left pl-3">{movie.views}</td>
                    <td className="py-2 text-left pl-3 line-clamp-3 text-xs">
                      <Link to={`/edit/${movie.id}`} className='bg-[#00acc1] px-2 py-1 text-white rounded-sm'>Edit</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>

        {/* Popular series */}
        <div className='bg-[#2f3441] p-3 md:p-5 text-gray-400 w-full'>
          <h2 className='text-white'>Most Popular Series</h2>
          <table className="w-full table-auto md:p-10 select-none overflow-visible">
              <thead>
                <tr className='border-b-[1px] border-gray-600'>
                  <th className="py-2 text-left pl-3">#</th>
                  <th className="py-2 text-left pl-3">Title</th>
                  <th className="py-2 text-left pl-3">Edit</th>
                </tr>
              </thead>
              <tbody>
                {series && series.slice(0, 5).map((serie, index) => (
                  <tr key={serie.id} className="cursor-pointer mb-10 border-b-[1px] border-gray-700 hover:bg-gray-600/30">
                    <td className="py-2 text-left pl-3">{index + 1}</td>
                    <td className="py-2 text-left pl-3">{serie.title}</td>
                    <td className="py-2 text-left pl-3 line-clamp-3 text-xs">
                    <Link to={`/edit-series/${serie.id}`} className='bg-[#00acc1] px-2 py-1 text-white rounded-sm'>Edit</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>

      </div>



      <div className='bg-[#2f3441] p-3 md:p-5 text-gray-400 mb-24 overflow-hidden'>
        <h2 className='text-white'>New users</h2>
        <div className='overflow-x-scroll'>
          <table className="w-full table-auto md:p-10 select-none">
              <thead>
                <tr className='border-b-[1px] border-gray-600'>
                  <th className="py-2 text-left pl-3">#</th>
                  <th className="py-2 text-left pl-3">Display Name</th>
                  <th className="py-2 text-left pl-3">Email</th>
                  <th className="py-2 text-left pl-3">Role</th>
                </tr>
              </thead>
              <tbody>
                {users && users.slice(0, 5).map((user, index) => (
                  <tr key={user.id} className="cursor-pointer mb-10 border-b-[1px] border-gray-700 hover:bg-gray-600/30">
                    <td className="py-2 text-left pl-3">{index + 1}</td>
                    <td className="py-2 text-left pl-3">{user.user_metadata && user.user_metadata.name ? user.user_metadata.name : user.user_metadata.username}</td>
                    <td className="py-2 text-left pl-3">{user.email}</td>
                    <td className="py-2 text-left pl-3 line-clamp-3">User</td>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
      </div>
    </div>
  )
}

export default Dashboard